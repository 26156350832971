import React from "react"
import SEO from "../components/seo"
import { Flex } from "rebass";
import theme from "../gatsby-plugin-theme-ui";
import { graphql } from "gatsby";
import Image from "../components/image";
import posed from "react-pose";
import { useColorMode } from 'theme-ui';
import FirstSlide from "../components/firstSlide";
import Markup from "../components/markup";
import { useMediaQuery } from "react-responsive";
import { path } from 'ramda';

const PosedImage = posed(Image)({
  active: {
    opacity: 1,
  },
  disabled: {
    opacity: 0,
  }
});

const NotFoundPage = ({ data: { bgDark, bgDefault, config, bgDarkMobile, bgDefaultMobile } }) => {
  const [colorMode] = useColorMode();
  const title = path(['title'], config);
  const subtitle = path(['subtitle'], config);
  const description = path(['description', 'processed'], config);
  const isTabletOrMobile = useMediaQuery({ maxWidth: theme.breakpoints[1] });
  return (
    <>
      <SEO title="404"/>
      <Flex alignItems="center" justifyContent="center" height={[`calc(100vh - 2 * ${theme.space[2]}px)`, null, `calc(100vh - 2 * ${theme.space[3]}px)`]} width="100%" sx={{ position: 'relative' }}>
        <PosedImage
          pose={colorMode === 'default' ? 'active' : 'disabled'}
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
          }}
          fluid={(isTabletOrMobile ? bgDefaultMobile : bgDefault).sharp.fluid}
        />
        <PosedImage
          pose={colorMode === 'default' ? 'disabled' : 'active'}
          sx={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            left: 0,
            top: 0,
          }}
          fluid={(isTabletOrMobile ? bgDarkMobile : bgDark).sharp.fluid}
        />
        <FirstSlide title={title} subtitle={subtitle} description={<Markup as="div" html={description}/>} />
      </Flex>
    </>
  );
};

export default NotFoundPage

export const query = graphql`
    query Query404 {
      bgDark: file(relativePath: {regex: "/404/bg-dark/"}) {
        id
        sharp: childImageSharp {
          fluid(maxWidth: 1920, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bgDefault: file(relativePath: {regex: "/404/bg-default/"}) {
        id
        sharp: childImageSharp {
          fluid(maxWidth: 1920, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bgDarkMobile: file(relativePath: {regex: "/404/bg-mobile-dark/"}) {
        id
        sharp: childImageSharp {
          fluid(maxWidth: 1024, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      bgDefaultMobile: file(relativePath: {regex: "/404/bg-mobile-default/"}) {
        id
        sharp: childImageSharp {
          fluid(maxWidth: 1024, quality: 50) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      config: configPagesPage404 {
        description: field_white_description {
          processed
        }
        subtitle: field_white_sub_title
        title: field_white_title
      }
    }
  
`